import React from 'react'

import { Container, Collapsible } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/onboarding', label: 'Onboarding' },
  [
    { link: '/onboarding/serviceAccount', label: 'CX Service Account' },
    { link: '/onboarding/abilities', label: 'Abilities' },
    { link: '/onboarding/nike', label: 'Nike' },
    { link: '/onboarding/techstack', label: 'Tech Stack' },
    { link: '/onboarding/tools', label: 'Tools' }
  ]
]

function ServiceAccount () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>CX Service Account Actions</h2>
      <Collapsible title='Unlock'>
        <ol>
          <li> Go to <a target='_blank' rel='noopener noreferrer' href='https://idlocker.nike.com/identityiq/home.jsf'>ID Locker</a> : Once logged in, select the menu options in the upper left corner of the screen.</li><br/>
          <li>Select "Nike Custom" : In the "Nike Custom" dropdown, select "GID Account Management". This will load the correct form needed to make your request.</li>
          <p><img src='/screenshots/IDLocker_GIDHomePage.png' alt='GitHub Settings Page' width='70%' /></p>

          <li>From the "Action" dropdown select the "Manage Existing GID" ( If you don't see this option, please reach out to your Manager/Lead who has access to it. )</li>
          <p><img src='/screenshots/IDLocker_GIDHomePageForm.png' alt='GitHub Settings Page' width='70%' /></p>

          <li>Select the GID account that you want to modify from the dropdown list.</li><br/>
          <p><img src='/screenshots/IDLocker_GIDHomePageForm2.png' alt='GitHub Settings Page' width='70%' /></p>

          <li>To Unlock the specific GID account, Select "Unlock GID" under the "Manage GID Action" and click on review.</li>
          <p><img src='/screenshots/IDLocker_GIDUnlock.png' alt='GitHub Settings Page' width='70%' /></p>

          <li>Click on submit.</li>
          <p><img src='/screenshots/IDLocker_GIDUnlockSubmit.png' alt='GitHub Settings Page' width='70%' /></p>
        </ol>
      </Collapsible>
      <Collapsible title='Password Reset'>
        <p>Select "Password Reset" under the "Manage GID Action" to change the existing password</p>
        <img src='/screenshots/IDLocker_GIDPasswordReset.png' alt='GitHub Settings Page' width='70%' />
      </Collapsible>
      <Collapsible title='How To Add GID Accounts to AD Groups'>
        <ul>
          <li>Go to ServiceNow (NikeNow) and submit a IAM General request ticket by going <a target='_blank' rel='noopener noreferrer' href='https://niketech.service-now.com/nikenow?id=sc_cat_item&sys_id=d569ae1fdb0a67c4901a6165ca9619b5'>here</a></li><br/>
          <li>In the short description section enter: "Add GID to AD Group"</li><br/>
          <p><img src='/screenshots/NikeNow_GIDForm.png' alt='GitHub Settings Page' width='70%' /></p>
          <li>In the "Request Details" section, copy/paste and answer the following:</li><br/>
          <ol>Name of GID(s):</ol><br/>
          <ol>GID Owner Name:</ol><br/>
          <ol>Name of AD Group(s):</ol><br/>
          <ol>AD Group Owner Name:</ol><br/>
          <ol>Is this AD Group in scope for SOX:</ol><br/>
          <ol>Applications that are accessible with this group:</ol><br/>
          <ol>Data Classification of the data accessible by this group:</ol><br/>
          <ol>GEO's involved with this group:</ol><br/>
          <ol>Business Justification/Use Case:</ol><br/>
          <li>In the Watchlist add any applicable AD group or GID owners other than yourself</li><br/>
          <li>In the "Special Instructions" section, please leave any comments, instructions or anything else that you would like the IAM team to know.</li><br/>
          <p>Below screenshot is just a reference on how to submit the request</p>
          <p><img src='/screenshots/NikeNow_GIDFormDetails.png' alt='GitHub Settings Page' width='70%' /></p>
          <li> Follow this <a target='_blank' rel='noopener noreferrer' href='https://confluence.nike.com/display/IAM/How+To+Add+GID+Accounts+to+AD+Groups'>guide</a> for more details</li>
        </ul>
      </Collapsible>
    </Container>
  )
}

export default ServiceAccount
