import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from '@nike/epic-react-ui'

function Onboarding () {
  return (
    <Container>
      <h3>Onboarding UI developers</h3>
      <ul>
        <li><Link to='/onboarding/nike'>About Nike</Link></li>
        <li><Link to='/onboarding/techstack'>Our Tech Stack</Link></li>
        <li><Link to='/onboarding/abilities'>CX Abilities</Link></li>
        <li><Link to='/onboarding/tools'>Work tools</Link></li>
        <li><Link to='/authorizations'>Getting authorizations</Link></li>
        <li><Link to='/workflows'>Workflows</Link></li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://confluence.nike.com/display/NGP/Products+We+Maintain'>
            Products we maintain
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://confluence.nike.com/display/NGP/Tom+Hamlin+Onboarding'>
            Tom Hamlin Onboarding
          </a>
        </li>
        <li><Link to='/onboarding/serviceAccount'>CX Service Account</Link></li>
      </ul>
    </Container>
  )
}

export default Onboarding
