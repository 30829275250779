import React from 'react'

import { Container, Collapsible } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/onboarding', label: 'Onboarding' },
  [
    { link: '/onboarding/techstack', label: 'Tech Stack' },
    { link: '/onboarding/abilities', label: 'Abilities' },
    { link: '/onboarding/tools', label: 'Tools' },
    { link: '/onboarding/nike', label: 'Nike' },
    { link: '/onboarding/serviceAccount', label: 'CX Service Account' }
  ]
]

function TechStack () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>CX Tech Stack</h2>
      <Collapsible title='Browser(s)'>
        <ul>
          <li>Chrome</li>
          <li>Firefox</li>
          <li>Safari</li>
          <li>Developer Tools</li>
        </ul>
      </Collapsible>
      <Collapsible title='HTML'>
        <ul>
          <li>HTML5</li>
        </ul>
      </Collapsible>
      <Collapsible title='CSS'>
        <ul>
          <li>CSS3</li>
          <li>Stylus</li>
        </ul>
      </Collapsible>
      <Collapsible title='HTTP'>
        <ul>
          <li>Web server</li>
          <li>API</li>
        </ul>
      </Collapsible>
      <Collapsible title='JavaScript'>
        <ul>
          <li>ES9</li>
        </ul>
      </Collapsible>
      <Collapsible title='Node.js'>
        <ul>
          <li>node</li>
          <li>npm</li>
          <li>nvm</li>
        </ul>
      </Collapsible>
      <Collapsible title='React'>
        <ul>
          <li>Hooks</li>
          <li>React scripts</li>
        </ul>
      </Collapsible>
      <Collapsible title='EPIC React Library'>
        <ul>
          <li>
            <a target='_blank' rel='noreferrer'
              href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=Epic+Design+System'>
              EPIC Design System
            </a>
          </li>
          <li>
            <a href='https://ep-styleguide.niketech.com/' target='_blank' rel='noreferrer'>
              Getting Started
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Okta'>
        <ul>
          <li>Okta-React</li>
        </ul>
      </Collapsible>
      <Collapsible title='GitHub'>
        <ul>
          <li>GitHub.com</li>
          <li>Actions</li>
        </ul>
      </Collapsible>
      <Collapsible title='Amazon Web Services'>
        <ul>
          <li>AWS Credentials</li>
          <li>CloudFront</li>
          <li>Terraform</li>
        </ul>
      </Collapsible>
      <Collapsible title='Testing'>
        <ul>
          <li>Jest</li>
          <li>TestCafe</li>
        </ul>
      </Collapsible>
      <Collapsible title='Logging'>
        <ul>
          <li>
            <a href='https://developer.niketech.com/docs/projects/nark/README' target='_blank' rel='noreferrer'>
              Nark
            </a>
          </li>
        </ul>
      </Collapsible>
    </Container>
  )
}

export default TechStack
