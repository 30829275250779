import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Collapsible, Container, Icon } from '@nike/epic-react-ui'
import BreadCrumbs from '../../Breadcrumbs'

const breadCrumbs = [
  { link: '/workflows', label: 'Workflows' },
  { link: '/workflows/devops', label: 'DevOps' },
  [
    { link: '/workflows/devops/github', label: 'GitHub' },
    { link: '/workflows/devops/environments', label: 'Environments' },
    { link: '/workflows/devops/terraform', label: 'Terraform' },
    { link: '/workflows/devops/deploy', label: 'Deployments' }
  ]
]

const ButtonStyle = {
  backgroundColor: '#2ea44f',
  color: 'white',
  borderRadius: '6px'
}

const ShellStyle = {
  backgroundColor: '#f7f7f7',
  border: '1px solid #c1c7d0',
  borderRadius: '3px',
  padding: '10px'
}

function GitHub () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2><img src='/icons/github.svg' height={20} alt='GitHub'/> GitHub Repositories</h2>

      <p>All the Customer Experience (CX) repositories are in GitHub:&nbsp;
        <a target='_blank' rel='noopener noreferrer' href='https://github.com/nike-cx'>
           https://github.com/nike-cx
        </a>
      </p>
      <li><a target='_blank' rel='noopener noreferrer'
        href='https://guides.github.com/introduction/flow/'>Understanding the GitHub flow</a></li>
      <li><a target='_blank' rel='noopener noreferrer'
        href='https://cli.github.com/'>Take GitHub to the command line</a></li>
      <p/>

      <div style={{ display: 'flex' }}>
        <Card icon='Stages' subtitle='Clone an existing Repository' height={160}>
          <br/>To start the development workflow, clone the repo
          <pre>git clone repo</pre>
        </Card>
        <div style={{ width: 20 }}/>
        <Card icon='Programs' subtitle='Create a Repository' height={160}>
          <br/>Create a new CX Repository using this template
          <pre>nike-cx/ui-template</pre>
        </Card>
        <div style={{ width: 20 }}/>
        <Card icon='ClassWorkout' subtitle='Migrate a Repository' height={160}>
          <br/>Create a new CX Repository using this template
          <pre>nike-cx/ui-template</pre>
        </Card>
      </div>

      <hr style={{ marginTop: 40 }} />
      <p>
        In addition to the UI code and test suites, each repository<br/>
        contains the DevOps tooling for creating the web <Link to='/workflows/devops/environments'>environments</Link><br/>
        and using GitHub Actions for <Link to='/workflows/devops/deploy'>deployments</Link>.
      </p>

      <hr style={{ marginTop: 40 }} />
      <Collapsible title='Clone repository'>
        Go to the repository's page on GitHub, click the&nbsp;
        <button type='button' style={ ButtonStyle }>Code</button> button,<br/>
        the SSH tab and the <Icon type='Programs' size='s'/> icon to copy the .git location,
        then use the
        <pre>git clone [paste]</pre> command in a terminal window
      </Collapsible>

      <Collapsible title='Create repository'>
        From the CX repositories page on GitHub, click the&nbsp;
        <button type='button' style={ ButtonStyle }>New</button> button
        and use our Repository template:
        <pre>nike-cx/ui-template</pre>
        and then clone the repository into a local directory.
        <hr/>
        Alternatively, from the terminal using <code>gh</code> and <code>git</code> command lines:
        <pre style={ ShellStyle }>{`$ gh repo create https://github.com/nike-cx/<SOME_REPO_NAME>
$ git remote set-url origin https://github.com/nike-cx/<SOME_REPO_NAME>.git
# Code away!`}
        </pre>
      </Collapsible>

      <Collapsible title='Copy repository'>
        To make a copy of a repo from another organization into the CX org:
        <pre style={ ShellStyle }>{`$ git clone <REPO_FROM_SOMEWHERE>
$ gh repo create https://github.com/nike-cx/<SOME_REPO_NAME>
$ git remote set-url origin https://github.com/nike-cx/<SOME_REPO_NAME>.git
$ git push`}
        </pre>
      </Collapsible>

      <hr style={{ marginTop: 40 }} />
      After the repository has been created,
      include the Terraform configuration
      and the GitHub Actions for deployment.
    </Container>
  )
}

export default GitHub
