import React from 'react'

import { Container, Collapsible } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/onboarding', label: 'Onboarding' },
  [
    { link: '/onboarding/tools', label: 'Tools' },
    { link: '/onboarding/abilities', label: 'Abilities' },
    { link: '/onboarding/techstack', label: 'Tech Stack' },
    { link: '/onboarding/nike', label: 'Nike' },
    { link: '/onboarding/serviceAccount', label: 'CX Service Account' }
  ]
]

function Tools () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>CX Work Tools</h2>
      <Collapsible title='Outlook'>
        <ul>
          <li>Email</li>
          <li>Web</li>
        </ul>
      </Collapsible>
      <Collapsible title='Slack'>
        <ul>
          <li>Channels</li>
          <li>Web</li>
        </ul>
      </Collapsible>
      <Collapsible title='Zoom'>
        <ul>
          <li>Web</li>
          <li>Phone</li>
        </ul>
      </Collapsible>
      <Collapsible title='Visual Studio Code'>
        <ul>
          <li>Editor</li>
        </ul>
      </Collapsible>
      <Collapsible title='Figma'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/display/DSM/Figma+Access'>
              Access
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.figma.com/file/OogUHYZzWYYXtcZ8rTBfGh/Platforms-of-Sport?node-id=33%3A5'>
              Platforms of Sport
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Miro'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://academy.miro.com/courses/gswm'>
              Getting Started with Miro
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://miro.com/app/board/o9J_lcz4eD0=/'>
              Customer Experience Team Kickoff
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://miro.com/app/board/o9J_lb1fJ0c=/'>
              Design &amp; Development Workflow
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://miro.com/app/board/o9J_lb14pdQ=/'>
              Deployments
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://miro.com/app/board/o9J_lV-Y2Xg=/'>
              Moving us towards getting work done
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://miro.com/app/board/o9J_lbB5PAs=/'>
              App Creation
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Terminal'>
        <ul>
          <li>Command line</li>
        </ul>
      </Collapsible>
      <Collapsible title='Browser'>
        <ul>
          <li>Chrome</li>
          <li>Firefox</li>
          <li>Safari</li>
          <li>Brave</li>
        </ul>
      </Collapsible>
      <Collapsible title='Jira'>
        <ul>
          <li>Stories</li>
          <li>Sprints</li>
        </ul>
      </Collapsible>
      <Collapsible title='Confluence'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/'>
              Confluence
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='GitHub'>
        <ul>
          <li>GitHub.com</li>
          <li>Actions</li>
        </ul>
      </Collapsible>
      <Collapsible title='AWS'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://aws.nike.com'>
              aws.nike.com
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://github.com/Nike-Inc/gimme-aws-creds'>
              gimme-aws-creds
            </a>
          </li>
          <li>CloudFront</li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.terraform.io/'>
              Terraform
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='TestCafe'>
        <ul>
          <li>Testing</li>
        </ul>
      </Collapsible>
    </Container>
  )
}

export default Tools
