import React from 'react'

import { Container } from '@nike/epic-react-ui'
import BreadCrumbs from '../../Breadcrumbs'

const breadCrumbs = [
  { link: '/workflows', label: 'Workflows' },
  [
    { link: '/workflows/development/github', label: 'GitHub' }
  ]
]

function DevGitHub () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2><img src='/icons/github.svg' height={20} alt='GitHub'/> GitHub</h2>

      <ol>
        <li>
          Start by pulling the latest code from the main branch:
          <pre>git checkout main; git pull</pre>
        </li>
        <li>
          Create a branch named CX-#### for your story:
          <pre>git checkout -b CX-####</pre>
        </li>
        <li>
          Work on it, and push the code each night to the repo:
          <pre>git push</pre>
        </li>
        <li>
          When you are ready to share it, create the dev branch:
          <pre>git checkout -b dev; git push</pre>
          That will automatically deploy the code to the dev environment<br/><br/>
        </li>
        <li>
          After approval, push it to the preprod branch:
          <pre>git checkout -b preprod; git push</pre>
          Then run the TestCafe test suite<br/><br/>
        </li>
        <li>
          After the change has been approved and all the tests have passed,<br/>
          create a PR from the preprod branch.<br/>
          Upon approval, it will merge the code to main<br/><br/>
        </li>
        <li>
          Finally, go back and delete the preprod, dev and your story branches<br/>
          (not other developer’s branches)
        </li>
      </ol>
    </Container>
  )
}

export default DevGitHub
