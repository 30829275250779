import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/workflows', label: 'Workflows' },
  { link: '/workflows/devops', label: 'DevOps' }
]

export default function DevOps () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>DevOps</h2>
      <div>
        Customer Experience uses AWS Cloudfront to serve up the React apps.
        <p>Start by setting up a GitHub <Link to='/workflows/devops/github'>repository</Link></p>
        <p>Create three deployment <Link to='/workflows/devops/environments'>environments</Link></p>
        <p>which are configured using <Link to='/workflows/devops/terraform'>terraform</Link></p>
        <p>and delivered using <Link to='/workflows/devops/deploy'>deployments</Link></p>
      </div>
    </Container>
  )
}
