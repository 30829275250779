import React from 'react'

import { Card, Container } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/authorizations', label: 'Authorizations' },
  [
    { link: '/authorizations/github', label: 'GitHub' },
    { link: '/authorizations/okta', label: 'Okta' },
    { link: '/authorizations/aws', label: 'AWS' }
  ]
]

function GitHub () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>GitHub</h2>
      <Card iconPath='/icons/github.svg' subtitle='add user to github' height={200}>
        <ol>
          <li>Open <a href='https://idlocker.nike.com/identityiq/home.jsf'>IDLocker</a></li>
          <li>Select the user</li>
          <li>Add them to the <code>app.GHEC.nike-cx</code> AD group</li>
          <li>Wait about 90 minutes for Okta to complete the request</li>
        </ol>
      </Card>
      <p><a href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=GHEC+Migration+FAQs'>
        GitHub Enterprise Account (GHEC) Migration FAQs
      </a></p>
      Also add CX users to the <code style={{ backgroundColor: '#F5F5F5' }}>Nike.Technology.CustomerExperience.USA</code> group
      to view this website
      <br/><hr/>
      <p>Full instructions are here:</p>
      <p>1) Open {' '}
        <a href='https://idlocker.nike.com/identityiq/home.jsf'>https://idlocker.nike.com/identityiq/home.jsf</a>
        {' '} in the browser, then click on the Manage User Access button
      </p>
      <img src='/screenshots/IDLocker_home.png' alt='IDLocker home page' width='80%' />
      <br/><br/>
      <p>2) Enter the name of the user followed by the Return key,<br/>
        click on the checkmark at the top left of the user's box,<br/>
        then click on the Next button at the bottom of the page
      </p>
      <img src='/screenshots/IDLocker_user.png' alt='IDLocker user selection page' width='80%' />
      <br/><br/>
      <p>3) Enter the group name <code>app.GHEC.nike-cx</code> in the search box<br/>
        or simply type <code>nike-cx</code> followed by the Return key.<br/>
        Click on the checkmark at the top left of both boxes,<br/>
        then click on the Next button at the bottom of the page
      </p>
      <img src='/screenshots/IDLocker_access.png' alt='IDLocker access selection page' width='80%' />
      <br/><br/>
      <p>4) Review the information,<br/>
        then click on the Submit button at the bottom of the page.<br/>
        It takes Okta about 90 minutes to process the request
      </p>
      <img src='/screenshots/IDLocker_submit.png' alt='IDLocker review/submit page' width='80%' />
    </Container>
  )
}

export default GitHub
