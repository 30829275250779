import React from 'react'
import { Link } from 'react-router-dom'

import { NavBar } from '@nike/epic-react-ui'

export default function Header () {
  const routes = [
    { text: 'Onboarding', path: '/onboarding' },
    { text: 'Authorizations', path: '/authorizations' },
    { text: 'Workflows', path: '/workflows' }
  ]

  return (
    <NavBar name='Platform Capabilities'
      routes={routes}
      slack={{ url: 'https://nikedigital.slack.com/archives/G01M291UWBC' }}
      RouterLink={({ href, ...linkProps }) => (
        <Link to={href} {...linkProps} />
      )}
    />
  )
}
