import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Container, Collapsible } from '@nike/epic-react-ui'

function Workflow () {
  return (
    <Container>
      <h3>Workflows</h3>
      <Collapsible title='Keep It Tight'>
        <p>
          <a target='_blank' rel='noopener noreferrer'
            href='https://keepittight.nike.com/'>
            Keep It Tight
          </a> is a global initiative empowering employees to take personal
          responsibility to safeguard and protect Nike, Inc.’s competitive advantage.
          The program equips employees with tools and training to Keep It Tight everyday.
        </p>
        <Card iconPath='/icons/keep-it-tight.png' title='NCDC'
          width='100%' height ='150px'>
          <p>
            If you are experiencing a cybersecurity threat or need to report suspicious activity,
            immediately contact the Nike Cyber Defense Center.
          </p>
          <p>
            Available 24/7 at NCDC@Nike.com or +1.503.532.7979.
          </p>
        </Card>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/display/GRCEEHQ/Corporate+Information+Security+EMEA'>
              Corporate Information Security (CIS)
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Operations'>
        <ul>
          <li><Link to='/workflows/devops'>DevOps</Link></li>
          <ul>
            <li><Link to='/workflows/devops/github'>GitHub</Link></li>
            <li><Link to='/workflows/devops/environments'>Environments</Link></li>
            <li><Link to='/workflows/devops/deploy'>Deployments</Link></li>
            <li><Link to='/workflows/devops/terraform'>Terraform</Link></li>
          </ul>
          <li>Monitoring</li>
          <li><a target='_blank' rel='noopener noreferrer'
            href='https://www.smashingmagazine.com/category/performance'>Performance</a></li>
        </ul>
      </Collapsible>
      <Collapsible title='Development'>
        <ul>
          <li><Link to='/workflows/development/github'>GitHub</Link></li>
          <li>UX Design - Figma</li>
          <li>React</li>
          <li>Epic React library</li>
        </ul>
      </Collapsible>
      <Collapsible title='Testing'>
        <ul>
          <li>Unit testing</li>
          <li><Link to='/workflows/testing/end-to-end'>End-to-end testing</Link></li>
        </ul>
      </Collapsible>
      <Collapsible title='CX'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=CX+Detailed+Intake+Form'>
                Intake
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://jira.nike.com/secure/RapidBoard.jspa?rapidView=12712'>
                Triage
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://jira.nike.com/secure/RapidBoard.jspa?rapidView=13145&projectKey=CX&view=planning.nodetail&epics=visible&issueLimit=100'>
                Backlog
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://jira.nike.com/secure/RapidBoard.jspa?rapidView=13145&projectKey=CX'>
                Jira Sprints
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://github.nike.com/cx/practices'>
                Engineering Standards and Practices
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/display/NGP/Customer+Experience+Ceremonies'>
                    Agile Ceremonies
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/display/NGP/CX+Team+Calendar'>
                    Team Calendar
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='UX'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.figma.com/files/845061392473249058/recent?fuid=900061026264684783'>
                    Design Standards
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='PlatCap'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=Platform+Capabilities+Working+Agreement'>
                    Squad Working Agreement
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=PlatCap+Tech+Stack'>
                    Tech Stack
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Resources'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.figma.com/file/iDYRF013idb4Cu8v5ONvWv/Nike-DS-Guidelines?node-id=0%3A1'>
                    Nike Design System Guidelines
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.figma.com/file/WHGv51Q14Zeq8XZ3Y6UIT3/Nike-DS-Iconography-Library?node-id=0%3A25'>
                    Nike Design System Iconography Library
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/EDS-Pattern-Library?node-id=0%3A1'>
                    Enterprise Design System Pattern Library
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://pages.github.nike.com/UXFoundation/nike-design-system-components/docs/storybook/?path=/story/intro-getting-started--page'>
                    Nike Design System Components
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://pages.github.nike.com/UXFoundation/nike-design-system-components/docs/storybook/?path=/story/icons-nike-design-system-icons--all-icons'>
                    Nike Design System Icons
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.figma.com/file/Rjno9g62ysQOtFxBEVSCoI/Epic-DS-Library?node-id=60%3A0'>
                    EPIC Design System
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://ep-styleguide.niketech.com/'>
                    EPIC React UI
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://tourguide.prod.commerce.nikecloud.com/welcome'>
                    Tour Guide
            </a>
          </li>
        </ul>
      </Collapsible>
    </Container>
  )
}

export default Workflow
