import React from 'react'

import { Container } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/authorizations', label: 'Authorizations' },
  [
    { link: '/authorizations/aws', label: 'AWS' },
    { link: '/authorizations/github', label: 'GitHub' },
    { link: '/authorizations/okta', label: 'Okta' }
  ]
]

function Aws () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>AWS</h2>
    </Container>
  )
}

export default Aws
