import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Container } from '@nike/epic-react-ui'

function Authorizations () {
  return (
    <Container>
      <h3>Authorizations</h3>
      <p>Current members of CX must add new users to the following services:</p>
      <div style={{ display: 'flex' }}>
        <Card iconPath='/icons/github.svg' subtitle='github' height={150}>
          <p><Link to='/authorizations/github'>Authorize user for GitHub</Link></p>
        </Card>
        <div style={{ width: 20 }}/>
        <Card iconPath='/icons/okta.svg' subtitle='okta' height={150}>
          <p><Link to='/authorizations/okta'>Authorize user for Okta</Link></p>
        </Card>
        <div style={{ width: 20 }}/>
        <Card iconPath='/icons/aws.svg' subtitle='aws' height={150}>
          <p><Link to='/authorizations/aws'>Authorize user for AWS</Link></p>
        </Card>
      </div>

      <p>After being added to GitHub,</p>
      <ul>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://github.com/enterprises/nike/sso'>
            Single Sign-on (SSO)
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://docs.github.com/en/github/authenticating-to-github/adding-a-new-ssh-key-to-your-github-account'>
            Adding ssh key to github
          </a>
        </li>
      </ul>
    </Container>
  )
}

export default Authorizations
