import React from 'react'

import { Container } from '@nike/epic-react-ui'
import BreadCrumbs from '../../Breadcrumbs'
// import SvgIcon from '../../SvgIcon'

const breadCrumbs = [
  { link: '/workflows', label: 'Workflows' },
  // { link: '/workflows/testing', label: 'Testing' },
  [
    { link: '/workflows/testing/end-to-end', label: 'End-to-End' },
    { link: '/workflows/testing/unit', label: 'Unit' }
  ]
]

function EndToEnd () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>End-to-End Testing</h2>
      {/*
      <div style={{ display: 'flex' }}>
        <Card iconPath={SvgIcon('env')} subtitle='dev' height={150}>
        </Card>
        <div style={{ width: 20 }}/>
        <Card iconPath={SvgIcon('env')} subtitle='preprod' height={150}>
        </Card>
        <div style={{ width: 20 }}/>
        <Card iconPath={SvgIcon('env')} subtitle='prod' height={150}>
        </Card>
      </div>
      <hr/>
      */}
      <div>
        End-to-End testing involves creating each user journey across the UI as a script,
        and repeating them with each web browser
        <ul>
          <li>The testing tool used across Nike is <a href='https://testcafe.io/'>TestCafe</a></li>
          <li>The Nike Automated Test team has provided
            a <a href='https://github.com/nike-ai8-geoprogram/testcafe_template'>Template</a>
          </li>
        </ul>
      </div>
    </Container>
  )
}

export default EndToEnd
