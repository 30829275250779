/*  The <Card> component in epic-react-ui has an iconPath prop
    that can use an <svg+xml> component.
    This function allows a user to create an icon with custom text,
    fill color and font-family.
*/

function SvgIcon (text, fill = 'gray',
  fontFamily = "'Helvetica Neue Medium', Helvetica, Arial, sans-serif") {
  const svg = `<svg xmlns='http://www.w3.org/2000/svg'>
    <style>
      .text {
        fill: ${fill};
        font-family: ${fontFamily};
      }
    </style>
    <text y='24' class='text'>${text}</text>
  </svg>`

  return 'data:image/svg+xml,' + encodeURI(svg)
}

export default SvgIcon
