import React from 'react'

import { Card, Container, Collapsible } from '@nike/epic-react-ui'
import BreadCrumbs from '../../Breadcrumbs'
import SvgIcon from '../../SvgIcon'

const breadCrumbs = [
  { link: '/workflows', label: 'Workflows' },
  { link: '/workflows/devops', label: 'DevOps' },
  [
    { link: '/workflows/devops/deploy', label: 'Deployments' },
    { link: '/workflows/devops/github', label: 'GitHub' },
    { link: '/workflows/devops/environments', label: 'Environments' },
    { link: '/workflows/devops/terraform', label: 'Terraform' }
  ]
]

const ShellStyle = {
  backgroundColor: '#f7f7f7',
  border: '1px solid #c1c7d0',
  borderRadius: '3px',
  padding: '10px'
}

/*eslint-disable */
function Deploy () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>Deployments</h2>
      <div style={{ display: 'flex' }}>
        <Card iconPath={SvgIcon('Dev')} subtitle='deploy to dev' height={150}>
          <pre>gimme-aws-creds --profile cx-test</pre>
          <pre>pipeline/client_deploy.sh dev</pre>
        </Card>
        <div style={{ width: 20 }}/>
        <Card iconPath={SvgIcon('Pre')} subtitle='deploy to preprod' height={150}>
          <pre>GitHub Action push branch</pre>
        </Card>
        <div style={{ width: 20 }}/>
        <Card iconPath={SvgIcon('Prod')} subtitle='deploy to prod' height={150}>
          <pre>GitHub Action PR merge to main</pre>
        </Card>
      </div>
      <hr/>
      <Collapsible title='AWS Credentials'>
        <div style={{ display: 'flex' }}>
          <div>
            To deploy a React app to AWS CloudFront, you need AWS credentials.
            An admin has to authorize you with
            this AWS WaffleIron account:<br/>
            <pre className='blue'>cx-platform-test (601323899993)</pre>
            When authorized, run this command in a terminal window
            <pre className='blue'>gimme-aws-creds</pre>
            enter a Multi-factor Authentication code and select
            <pre className='blue'>[0] AWS WaffleIron Multi-Account</pre>
            then pick this role
            <pre className='blue'>arn:aws:iam::601323899993:role/NIKE.SSO.AdminRole</pre>
          </div>
          <div style={{ paddingLeft: 10, borderLeft: '1px solid gray' }}>
            The command can be reduced to a one-line command
            <pre className='blue'>gimme-aws-creds --profile cx-test</pre>
            by adding the following to your <code>~/.okta_aws_login_config</code> file<br/>
            and replacing the <code className='blue'>CLIENT_ID</code>,{' '}
            <code className='blue'>YOUR_EMAIL_HERE</code> and{' '}
            <code className='blue'>YOUR_UNIQUE_DEVICE_TOKEN</code> values
            <pre style={{ backgroundColor: '#F5F5F5' }}>
[cx-test]<br/>
okta_org_url = https://nike.okta.com<br/>
okta_auth_server = aus27z7p76as9Dz0H1t7<br/>
client_id = <span className='blue'>CLIENT_ID</span><br/>
gimme_creds_server = https://api.sec.nikecloud.com/gimmecreds/accounts<br/>
aws_appname = AWS WaffleIron Multi-Account<br/>
aws_rolename = arn:aws:iam::601323899993:role/NIKE.SSO.AdminRole<br/>
write_aws_creds = True<br/>
cred_profile = ngp<br/>
okta_username = <span className='blue'>YOUR_EMAIL_HERE@nike.com</span><br/>
app_url =<br/>
resolve_aws_alias = False<br/>
preferred_mfa_type = push<br/>
aws_default_duration = 3600<br/>
device_token = <span className='blue'>YOUR_UNIQUE_DEVICE_TOKEN</span>
            </pre>
          </div>
        </div>
      </Collapsible>
      <hr/>
      <Collapsible title='Pipeline'>
        The GitHub repo should have a folder named <code className='blue'>pipeline</code>{' '}
        with the following script:<br/>
        <code className='blue'>client-deploy.sh</code> performs the React app build
        and deployment to an S3 bucket.<br/>
        Replace <code className='blue'>APPNAME</code> with your UI app's name
        <pre style={{ backgroundColor: '#F5F5F5' }}>
#!/bin/bash<br/>
###########################<br/>
## Use for local deploys ##<br/>
###########################<br/>
set -e<br/>
<br/>
# Environments<br/>
case "$1" in<br/>
&nbsp;&nbsp;dev)<br/>
&nbsp;&nbsp;&nbsp;&nbsp;WORKSPACE="dev"<br/>
&nbsp;&nbsp;&nbsp;&nbsp;;;<br/>
&nbsp;&nbsp;preprod)<br/>
&nbsp;&nbsp;&nbsp;&nbsp;WORKSPACE="preprod"<br/>
&nbsp;&nbsp;&nbsp;&nbsp;;;<br/>
&nbsp;&nbsp;prod)<br/>
&nbsp;&nbsp;&nbsp;&nbsp;WORKSPACE="prod"<br/>
&nbsp;&nbsp;&nbsp;&nbsp;;;<br/>
&nbsp;&nbsp;*)<br/>
&nbsp;&nbsp;&nbsp;&nbsp;echo $"Usage: $0 { '{dev|preprod|prod}' }"<br/>
&nbsp;&nbsp;&nbsp;&nbsp;exit 1<br/>
esac<br/>
echo "Will deploy site for ${ '{WORKSPACE}' } environment"<br/>
<br/>
# Skip building files if we are in a GitHub Actions flow (files will have already been built)<br/>
if [ ! $CI  ]<br/>
then<br/>
&nbsp;&nbsp;npm i<br/>
&nbsp;&nbsp;npm run build<br/>
else <br/>
&nbsp;&nbsp;echo "Skipping build step; using pre-built files"<br/>
fi<br/><br/>
# S3 bucket name<br/>
CLIENT_BUCKET_NAME="<span className='blue'>APPNAME</span>-${ '{WORKSPACE}' }-client"<br/><br/>
# Push client code to s3<br/>
aws s3 rm s3://$CLIENT_BUCKET_NAME/&nbsp;&nbsp;--recursive<br/>
aws s3 sync build/ s3://$CLIENT_BUCKET_NAME/ --exclude \"*.DS_Store*\"<br/>
aws s3 cp build/index.html s3://$CLIENT_BUCKET_NAME/ --cache-control max-age=0
        </pre>
        <p>The <code className='blue'>CLIENT_BUCKET_NAME</code> is defined as a variable in pipeline/terraform/variables.tf</p>
      </Collapsible>
      <hr/>
      <Collapsible title='GitHub Actions'>
        <p>Documentation on <a target='_blank' rel='noopener noreferrer'
          href='https://docs.github.com/en/actions'>GitHub Actions</a>
        </p>

        <Collapsible title='Description'>
          <ul>
            <li>Workflow files use YAML syntax, and must have either a .yml or .yaml file extension.</li>
            <li>You must store workflow files in the `.github/workflows` directory of your repository.</li>
            <li>`Github Actions` works off of [triggers](https://docs.github.com/en/actions/reference/events-that-trigger-workflows)</li>
            <li>Common webhook events: push, release, pull_request, create, delete, issues</li>
            <li>There is also `schedule`, `workflow_dispatch`, & `repository_dispatch`</li>
            <li>There are 3 main pieces to the actions yaml file:
              <ol>
                <li>`name` (whatever name you want to call this workflow, this will show up in the GUI)</li>
                <li>`on` (The trigger, it can be a string or an array of strings)</li>
                <li>`jobs`</li>
              </ol>
            </li>
            <li>Defining the trigger `on`
              <pre style={ ShellStyle }>{`yaml (./.github/workflows/hello.yml)
# Triggered when code is pushed to any branch in a repository
on: push          # Triggers the workflow on push or pull request events
on: [push, pull_request].   # Ignore a branch
on:
  push:
    branches-ignore:
      - main`}
              </pre>
            </li>
          </ul>
        </Collapsible>

        <Collapsible title='Setup'> 
          Before implementing GitHub Actions, be sure to do the following. 

          <h3>Subscribe to Slack Notifications</h3>
              <p>Follow the <a target='_blank' rel='noopener noreferrer' href='https://nikedigital.slack.com/archives/C0265F42UAF'>#platcap-git-notifications</a> slack channel to recieve deployment notifications and approval requests</p> 
          
          <h3>Enabling New Relic Monitoring</h3>
          <ol>
            <li>Request access to New Relic by following {' '}
              <a target='_blank' rel='noopener noreferrer' href='https://niketech.service-now.com/nikenow?id=nike_kb_article&sys_id=0bae0c5013fe434090b37b304244b0f4'>this guide</a>
            </li>
            <li>In <code>public/index.html</code>, make sure you have the following snippet:</li>
            <pre style={{ backgroundColor: '#F5F5F5' }}>          
              <code>
                {`<!DOCTYPE html>
  <html lang="en">
    <head>
    // ... (all meta and link tags)

    <!--NEW RELIC SCRIPT HOLDER: DO NOT REMOVE-->
    <script type="newrelic/placeholder"></script>

    // ... (the rest of index.html)
  </html>`}
              </code>
            </pre>

            <li>After deploying (with the <code>Alternative Implementation</code> GitHub Actions flow found further on in this documentation), check the {' '}
              <a href='https://one.newrelic.com/launcher/nr1-core.explorer?platform[accountId]=1305512&platform[filters]=Iihkb21haW4gPSAnQlJPV1NFUicgQU5EIHR5cGUgPSAnQVBQTElDQVRJT04nKSI=&pane=eyJuZXJkbGV0SWQiOiJucjEtY29yZS5saXN0aW5nIiwiZW50aXR5RXhwbG9yZXJQcm9kdWN0UmVkaXJlY3Rpb24iOnRydWUsImZhdm9yaXRlcyI6eyJzZWxlY3RlZCI6dHJ1ZSwidmlzaWJsZSI6dHJ1ZX0sImxhc3RWaWV3ZWQiOnsic2VsZWN0ZWQiOmZhbHNlLCJ2aXNpYmxlIjp0cnVlfX0=&sidebars[0]=eyJuZXJkbGV0SWQiOiJucjEtY29yZS5jYXRlZ29yaWVzIiwicm9vdE5lcmRsZXRJZCI6Im5yMS1jb3JlLmxpc3RpbmciLCJmYXZvcml0ZXMiOnsic2VsZWN0ZWQiOnRydWUsInZpc2libGUiOnRydWV9LCJsYXN0Vmlld2VkIjp7InNlbGVjdGVkIjpmYWxzZSwidmlzaWJsZSI6dHJ1ZX19&state=04db5fc2-05ca-ad67-8e54-ca03e4eac00e'>
                <code>tech-prd</code> New Relic account
              </a>
              {' '} for your new Browser Application.
            </li>
            <ul>
              <li>Your production application will be named <code>nike-cx/{`{repository-name}`}</code></li>
              <li>Dev and preprod applications will be named <code>nike-cx/{`{repository-name-dev}`}</code> and <code>nike-cx/{`{repository-name-preprod}`}</code></li>
            </ul>
            <li>For each of these browser monitoring applications, 
              navigate to the "Application Settings" link found on the left-hand navigation pane (see the <i>Screen Captures</i> section for a visual)</li>
            <li>Select the <code>Pro + SPA</code> option to enable monitoring of all <code>fetch</code> requests</li>
          </ol>
          <h3>Gate Production Deployment</h3>
          These steps ensure that your final production deployment is gated behind a manual approval.
          <ol>
            <li>Navigate to the <code>Settings</code> tab of your repository</li>
            <li>Click on the <code>Environments</code> tab on the left pane (see Figure 1)</li>
            <li>Click the <code>prod</code> environment link</li>
            <li>Check <code>Require Reviewers</code> and specify <code>nike-cx/cx-platform-capabilities</code></li>
            <li>Click <code>Save Protection Rules</code></li>
            <br />
            <h3>Screen Captures</h3>
            <hr />
            <img src='/screenshots/github_settings.png' alt='GitHub Settings Page' width='70%' />
            <img src='/screenshots/github_env_rules.png' alt='GitHub Settings Page' width='70%' />
            <img src='/screenshots/new_relic_settings.png' alt='New Relic Settings Page' width='70%' />
          </ol>
        </Collapsible>

        <Collapsible title='Implementation'> 
          The GitHub repo must have a folder named <code>.github/workflows/</code> with
          one YAML file for each environment.<br/>
          The code in each Action file performs four steps:
          <ol>
            <li>it checks out the code from the repo</li>
            <li>assumes the AWS role for the environment</li>
            <li>runs <code>./pipeline/client-deploy.sh env</code> to
              build the app and deploy the build</li>
            <li>then notifies the Slack channel</li>
          </ol>
          The three files are below:
          <Collapsible title='prod.yaml'>
            <pre style={{ backgroundColor: '#F5F5F5', whiteSpace: 'pre-wrap' }}>
name: Deploy Prod<br/>
<br/>
on:<br/>
&nbsp;&nbsp;push:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;branches:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;- main<br/>
<br/>
jobs:<br/>
&nbsp;&nbsp;deploy_prod:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;runs-on: self-hosted<br/>
&nbsp;&nbsp;&nbsp;&nbsp;environment: prod # This line gates deployment with a manual approval<br/>
&nbsp;&nbsp;&nbsp;&nbsp;steps:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- uses: actions/checkout@v1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- name: Configure AWS Credentials<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;uses: aws-actions/configure-aws-credentials@v1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;with:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;aws-region: us-west-2<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;role-duration-seconds: 3600<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;role-to-assume: ${ '{{ secrets.CI_ROLE_PLAT_PROD }}' }<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- name: Deploy<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;run: ./pipeline/client-deploy.sh prod<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- name: Notify Build<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;run: |<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;curl -X POST -H 'Content-type: application/json' --data { `'{"text":"Deploy to prod completed for '\${{github.repository}}'' \${{ secrets.PLAT_CAP_SLACK_HOOK }}` }
              </pre>
            </Collapsible>
            <Collapsible title='preprod.yaml'>
              <pre style={{ backgroundColor: '#F5F5F5', whiteSpace: 'pre-wrap' }}>
name: Deploy preprod<br/>
<br/>
on:<br/>
&nbsp;&nbsp;push:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;branches:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;- preprod<br/>
<br/>
jobs:<br/>
&nbsp;&nbsp;deploy_preprod:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;runs-on: self-hosted<br/>
&nbsp;&nbsp;&nbsp;&nbsp;steps:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- uses: actions/checkout@v1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- name: Configure AWS Credentials<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;uses: aws-actions/configure-aws-credentials@v1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;with:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;aws-region: us-west-2<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;role-duration-seconds: 3600<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;role-to-assume: ${ '{{ secrets.CI_ROLE_PLAT_TEST }}' }<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- name: Deploy<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;run: ./pipeline/client-deploy.sh preprod<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- name: Notify Build<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;run: |<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;curl -X POST -H 'Content-type: application/json' --data { `'{"text":"Deploy to preprod completed for '\${{github.repository}}', go to <\${{env.GITHUB_SERVER_URL}}/\${{env.GITHUB_REPOSITORY}}/actions/runs/\${{env.GITHUB_RUN_ID}}>|Workflow> to approve for production deploy"}' \${{ secrets.PLAT_CAP_SLACK_HOOK }}` }
              </pre>
            </Collapsible>
            <Collapsible title='dev.yaml'>
              <pre style={{ backgroundColor: '#F5F5F5', whiteSpace: 'pre-wrap' }}>
name: Deploy dev<br/>
<br/>
on:<br/>
&nbsp;&nbsp;push:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;branches:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;- dev<br/>
<br/>
jobs:<br/>
&nbsp;&nbsp;deploy_dev:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;runs-on: self-hosted<br/>
&nbsp;&nbsp;&nbsp;&nbsp;steps:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- uses: actions/checkout@v1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- name: Configure AWS Credentials<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;uses: aws-actions/configure-aws-credentials@v1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;with:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;aws-region: us-west-2<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;role-duration-seconds: 3600<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;role-to-assume: ${ '{{ secrets.CI_ROLE_PLAT_TEST }}' }<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- name: Deploy<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;run: ./pipeline/client-deploy.sh dev<br/>
            </pre>
          </Collapsible>
        </Collapsible>
     
      <Collapsible title='An Alternative Implementation'> 
        The GitHub repo must have a folder named <code>.github/workflows/</code> with
        one YAML file for deployment and one YAML file for building.<br/>
        The deployment action will run with every PR to your <code>main</code> branch. The build action will run with every PR to ensure that all tests pass.
        <br/><br/>
        The code in the <code>deploy.yaml</code> file performs the following jobs (<code>build.yaml</code> performs only the first job, steps 1-3):
        <ol>
          <li>Build</li>
          <ol>
            <li>Checks out the code from the repo</li>
            <li>Install the necessary dependencies and run linter and tests</li>
            <li>Runs <code>npm run build</code> to build the app</li>
            <li>Saves the build as an artifact of this workflow</li>
          </ol>
          
          <li>Deploy Dev</li>
          <ol>
            <li>Checks out the previously uploaded artifact (the build)</li>
            <li>Injects New Relic monitoring script for the dev browser app</li>
            <li>Assumes the AWS role for the environment</li>
            <li>Deploys to the dev environment</li>
          </ol>

          <li>Deploy Preprod</li>
          <ol>
            <li>Checks out the previously uploaded artifact (the build)</li>
            <li>Injects New Relic monitoring script for the preprod browser app</li>
            <li>Assumes the AWS role for the environment</li>
            <li>Deploys to the preprod environment</li>
            <li>Notifies the Slack channel that there is a deployment now awaiting approval</li>
          </ol>

          <li>Deploy Prod</li>
          <ol>
            <li><i>After</i> the approval of the previous job...</li>
            <li>Checks out the previously uploaded artifact (the build)</li>
            <li>Injects New Relic monitoring script for the prod browser app</li>
            <li>Assumes the AWS role for the environment</li>
            <li>Deploys to the pord environment</li>
            <li>Notifies the Slack channel that there has been a production deployment</li>
          </ol>
        </ol>
        The two files are below:

        <Collapsible title='deploy.yaml'>
          <pre style={{ backgroundColor: '#F5F5F5', whiteSpace: 'pre-wrap' }}>
        {
`name: Deploy

on:
  push:
    branches:
      - 'main'

jobs:
  build:
    runs-on: self-hosted
    steps:
      - uses: actions/checkout@v1
      - name: Install Dependencies
        run: npm ci
      - name: Run linter
        run: npm run lint
      - name: Run Tests
        run: npm run test:ci
      - name: Build Library
        run: npm run build
      - name: Save Build
        uses: actions/upload-artifact@v2
        with:
          name: build
          path: |
            build
            pipeline
            package.json
            scripts

  deploy_dev:
    runs-on: self-hosted
    needs: build
    env:
      NR_API_KEY: \${{ secrets.NGP_APPS_NEW_RELIC_API_KEY }}
      NR_APP_NAME: '\${{ github.repository }}-dev'
    steps:
      - name: Fetch Build
        uses: actions/download-artifact@v2
        with:
          name: build
      - name: New Relic Instrumentation
        run: ./scripts/newrelic.sh
      - name: Configure AWS Credentials
        uses: aws-actions/configure-aws-credentials@v1
        with:
          aws-region: us-west-2
          role-duration-seconds: 3600
          role-to-assume: \${{ secrets.CI_ROLE_PLAT_TEST }}
      - name: Deploy
        run: ./pipeline/client-deploy.sh dev

  deploy_preprod:
    runs-on: self-hosted
    needs: deploy_dev
    env:
      NR_API_KEY: \${{ secrets.NGP_APPS_NEW_RELIC_API_KEY }}
      NR_APP_NAME: '\${{ github.repository }}-preprod'
    steps:
      - name: Fetch Build
        uses: actions/download-artifact@v2
        with:
          name: build
      - name: New Relic Instrumentation
        run: ./scripts/newrelic.sh
      - name: Configure AWS Credentials
        uses: aws-actions/configure-aws-credentials@v1
        with:
          aws-region: us-west-2
          role-duration-seconds: 3600
          role-to-assume: \${{ secrets.CI_ROLE_PLAT_TEST }}
      - name: Deploy
        run: ./pipeline/client-deploy.sh preprod
      - name: Notify Build
        run: |
          curl -X POST -H 'Content-type: application/json' --data '{"text":"Deploy to preprod completed for '\${{ github.repository }}', go to </\${{ github.repository }}/actions/runs/\${{ env.GITHUB_RUN_ID }}>|Workflow> to approve for production deploy"}' \${{ secrets.PLAT_CAP_SLACK_HOOK }}

  deploy_prod:
    runs-on: self-hosted
    needs: deploy_preprod
    environment: prod
    env:
      NR_API_KEY: \${{ secrets.NGP_APPS_NEW_RELIC_API_KEY }}
      NR_APP_NAME: \${{ github.repository }}
    steps:
      - name: Fetch Build
        uses: actions/download-artifact@v2
        with:
          name: build
      - name: New Relic Instrumentation
        run: ./scripts/newrelic.sh
      - name: Configure AWS Credentials
        uses: aws-actions/configure-aws-credentials@v1
        with:
          aws-region: us-west-2
          role-duration-seconds: 3600
          role-to-assume: \${{ secrets.CI_ROLE_PLAT_PROD }}
      - name: Deploy
        run: ./pipeline/client-deploy.sh prod
      - name: Notify Build
        run: |
          curl -X POST -H 'Content-type: application/json' --data '{"text":"Deploy to prod completed for '\${{ github.repository }}'"}' \${{ secrets.PLAT_CAP_SLACK_HOOK }}`
}
</pre>
        </Collapsible>
        <Collapsible title='build.yaml'>
          <pre style={{ backgroundColor: '#F5F5F5', whiteSpace: 'pre-wrap' }}>
            {
`name: Build and Test

on:
  push:
    branches-ignore:
      - main

jobs:
  build:
    runs-on: self-hosted
    steps:
      - uses: actions/checkout@v1
      - name: Install Dependencies
        run: npm ci
      - name: Run linter
        run: npm run lint
      - name: Run Tests
        run: npm run test:ci
      - name: Build Library
        run: npm run build
      - name: Save Build
        uses: actions/upload-artifact@v2
        with:
          name: build
          path: |
            build
            pipeline
            package.json
            scripts`
}
          </pre>
        </Collapsible>
      </Collapsible>

     </Collapsible>

  </Container>
  )
}

export default Deploy
