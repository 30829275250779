import React from 'react'

import { Container, Collapsible } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/onboarding', label: 'Onboarding' },
  [
    { link: '/onboarding/abilities', label: 'Abilities' },
    { link: '/onboarding/nike', label: 'Nike' },
    { link: '/onboarding/techstack', label: 'Tech Stack' },
    { link: '/onboarding/tools', label: 'Tools' },
    { link: '/onboarding/serviceAccount', label: 'CX Service Account' }
  ]
]

function Abilities () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>Abilities</h2>
      <p>Abilities, also known as capabilities and "ilities,"
        are the technical features required by the services
        to support development and usability.
      </p>
      <Collapsible title='Scalability'>
        A service must be able to handle increased workload,
        and to be deployed to multiple servers around the world
      </Collapsible>
      <Collapsible title='Security'>
        Each service must follow the Nike maxim to "Keep it Tight"
        and restrict access to data and other services
      </Collapsible>
      <Collapsible title='Accessibility'>
        In addition to supporting multiple browsers and desktop computers,
        each UI should also support human accessibility needs
      </Collapsible>
      <Collapsible title='Observability'>
        Services should record activity with monitoring tools like New Relic
        and report errors to Slack and PagerDuty
      </Collapsible>
      <Collapsible title='Extensibility'>
        As the underlying platforms and APIs continue to change,
        the UI should be able to be extended to keep up with the changes
      </Collapsible>
      <Collapsible title='Deployability'>
        Each service must be deployed to multiple environments,
        dev, preprod and prod
      </Collapsible>
      <Collapsible title='Portability'>
        Not yet a requirement, but the ability to deploy to multiple cloud platforms
      </Collapsible>
      <Collapsible title='Reliability'>
        Each service should not only be tested during deployment to ensure
        that it is fully working, it should also be able to handle API errors
        and other external problems
      </Collapsible>
      <Collapsible title='Maintainability'>
        The ideal service is quick and easy to maintain, with good documentation
        and well structured code
      </Collapsible>
      <Collapsible title='Onboardability'>
        As part of our Platforms Console, it should be a clear process to
        bringing in new UIs and adding them to the list of services
      </Collapsible>
    </Container>
  )
}

export default Abilities
