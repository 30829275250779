import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Container } from '@nike/epic-react-ui'
import BreadCrumbs from '../../Breadcrumbs'
import SvgIcon from '../../SvgIcon'

const breadCrumbs = [
  { link: '/workflows', label: 'Workflows' },
  { link: '/workflows/devops', label: 'DevOps' },
  [
    { link: '/workflows/devops/environments', label: 'Environments' },
    { link: '/workflows/devops/github', label: 'GitHub' },
    { link: '/workflows/devops/deploy', label: 'Deployments' },
    { link: '/workflows/devops/terraform', label: 'Terraform' }
  ]
]

function Environments () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>Environments</h2>
      <div style={{ display: 'flex' }}>
        <Card iconPath={SvgIcon('env')} subtitle='dev' height={150}>
          Development environment for testing new features
          and sharing them with UX and customers
        </Card>
        <div style={{ width: 20 }}/>
        <Card iconPath={SvgIcon('env')} subtitle='preprod' height={150}>
          Pre-Production environment for testing a GitHub branch
          before deploying to Production
        </Card>
        <div style={{ width: 20 }}/>
        <Card iconPath={SvgIcon('env')} subtitle='prod' height={150}>
          Production environment, used by customers
        </Card>
      </div>
      <hr/>
      <div>
        CloudFront must be configured to hold at least three destinations,
        called environments.
        <ul>
          <li>
            The Development environment, labeled 'dev', which is used
            to <Link to='/workflow/devops/deploy'>deploy</Link> work in process.
            This is the location that can be shared with
            the CX team and customers before starting the testing process.
          </li>
          <li>
            The Testing environment, lableled 'preprod', which is used to
            test the new branch before merging it with the main branch,
            which then releases it to prod.
          </li>
          <li>
            The Production environment, labeled 'prod', which holds the
            customer facing app.  A developer never deploys directly to prod
            because it uses a different WaffleIron account (cx-platform-prod)
            and is only deployed when the GitHub main branch has been merged
            with another branch after the branch was tested and a pull request
            has been approved.
          </li>
        </ul>
        CloudFront is configured using <Link to='/workflow/devops/terraform'>Terraform</Link>,
        a one-time process.
      </div>
    </Container>
  )
}

export default Environments
