import React from 'react'

import { Container, Collapsible } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/onboarding', label: 'Onboarding' },
  [
    { link: '/onboarding/nike', label: 'Nike' },
    { link: '/onboarding/abilities', label: 'Abilities' },
    { link: '/onboarding/techstack', label: 'Tech Stack' },
    { link: '/onboarding/tools', label: 'Tools' },
    { link: '/onboarding/serviceAccount', label: 'CX Service Account' }
  ]
]

function Nike () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2><img src='/logo.png' width={48} alt='Swoosh'/> Nike</h2>
      <p>This is a short description of Nike and how the Customer Experience team serves the business capabilities.</p>
      <p>Nike is a global sports company that serves athletes in many sports from over 1000 stores in over 170 countries.</p>
      <p>With the onset of Covid-19, more customers started using our online services.</p>
      <p>Nike also increased the use of analytics and machine learning to assist with business capabilities.</p>
      <p>Nike adopted a "Composable Platform" strategy of building data platforms that can be assembled like Duplo blocks.</p>
      <p>Each platform needs a User Interface (UI) that helps business users access the capabilities.</p>
      <Collapsible title='Nike'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://zero.nike.com/home/story/zero_archives/the-maxims/55176'>
              The Maxims
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://zero.nike.com/home/'>
              Zero
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.nike.com/'>
              Nike.com
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://www.nike.com/nike-app'>
              Nike App
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://zero.nike.com/home/story/zero_archives/consumer-direct-acceleration-resources/54236'>
              Consumer Direct Acceleration (CDA)
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Global Technology'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/display/SERVE/Global+Technology'>
              Global Technology
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/pages/viewpage.action?pageId=284628994'>
              Consumer &amp; Marketplace
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/display/NEA/Global+Technology+Platform+Strategy'>
              Platform Strategy
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Enterprise Platforms'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/display/NGP/Enterprise+Platforms'>
              Enterprise Platforms
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://docs.npe.nike.com/'>
              Platform Engine
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/pages/viewpage.action?spaceKey=CSDE&title=0.1+EAP+OKR%27s'>
              EAP OKRs
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/display/NEA/1.10+Platform+COE'>
              Platform COE
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Customer Experience'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=Customer+Experience'>
              Developer Enablement and Tooling
            </a>
          </li>
        </ul>
      </Collapsible>
      <Collapsible title='Employees'>
        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://zero.nike.com/home/story/zero_archives/draft-home-workplace-set-up-faqs/55238'>
              Workplace
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer'
              href='https://confluence.nike.com/pages/viewpage.action?pageId=298503315'>
              Workforce Excellence
            </a>
          </li>
        </ul>
      </Collapsible>
    </Container>
  )
}

export default Nike
